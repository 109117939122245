import React from 'react'
import { graphql } from 'gatsby'
import { Box, Heading, Text } from 'rebass'
import Link from '../components/base/Link'
import Hero from '../components/base/Hero'
import { modelSlugToRoute } from '../utils/links'

export default ({ data: { datoCmsFaq: faq } }) => (
  <>
    <Hero>
      <Heading as="h1" variant="hero.heading">FAQ</Heading>
      <Heading as="h2" variant="hero.subheading">{faq.question}</Heading>
    </Hero>
    <Box variant="fixed">
      <Box my={5}>
        <Text dangerouslySetInnerHTML={{ __html: faq.answerNode.childMarkdownRemark.html }} />
      </Box>
      {faq.relevantFaqs.length > 0 && (
        <Box my={5}>
          <Heading variant="heading">Related Questions</Heading>
          <ul>
              {faq.relevantFaqs.map(r => <li key={r.id}>
                  <Link to={modelSlugToRoute('faq', r.slug)}>{r.question}</Link>
              </li>)}
          </ul>
        </Box>
      )}
      <Box my={5}>
        <Link to="/faqs">Back to all FAQs</Link>
      </Box>
    </Box>
  </>
)

export const query = graphql`
  query FaqQuery($slug: String!) {
    datoCmsFaq(slug: { eq: $slug }) {
      id
      categories {
        id
      }
      question
      answerNode {
        childMarkdownRemark {
          html
        }
      }
      relevantFaqs {
        id
        slug
        question
      }
    }
  }
`
